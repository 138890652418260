<template>
  <div class="bg-light-warning p-8 rounded-xl flex-grow-1">
    <!--begin::Item-->
    <div class="d-flex align-items-center mb-5">
      <div class="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
        <div class="symbol-label">
          <span class="svg-icon svg-icon-md svg-icon-danger">
            <i class="flaticon-mail text-primary"></i>
          </span>
        </div>
      </div>
      <div>
        <div class="font-size-sm font-weight-bold">{{ value.openjob }}</div>
        <div class="font-size-sm text-muted">Open Job</div>
      </div>
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="d-flex align-items-center mb-5">
      <div class="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
        <div class="symbol-label">
          <span class="svg-icon svg-icon-md svg-icon-warning">
            <i class="flaticon2-check-mark text-success"></i>
          </span>
        </div>
      </div>
      <div>
        <div class="font-size-sm font-weight-bold">{{ value.closejob }}</div>
        <div class="font-size-sm text-muted">Closed Job</div>
      </div>
    </div>
    <!--end::Item-->
    <!--begin::Item-->
    <div class="d-flex align-items-center mb-5">
      <div class="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
        <div class="symbol-label">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <i class="flaticon2-rubbish-bin text-grey"></i>
          </span>
        </div>
      </div>
      <div>
        <div class="font-size-sm font-weight-bold">{{ value.canceljob }}</div>
        <div class="font-size-sm text-muted">Cancelled Job</div>
      </div>
    </div>
    <!--end::Item-->
  </div>
</template>

<script>
export default {
  props: ["value"]
};
</script>
